$(document).ready(function () {
    $.ajax({
        url: 'https://referentiel.kifal.ma/api/brands/full',
        type: 'GET',
        success: function (result) {
            $.each(result.data, function (key, value) {
                brandsContent = '<option  value="' + value.brand_name + '">' + value.brand_name + '</option>';
                $('select.brands-select').append(brandsContent);
            });
        }
    });

    function getModeleInselectMarque(brand) {
        $.ajax({
            url: 'https://referentiel.kifal.ma/api/models?brand=' + brand,
            type: 'GET',
            success: function (result) {
                $('#list_models_filter').html("<option value='' " +
                    // "disabled selected" +
                    ">Modèle</option>");
                $.each(result.data, function (key, value) {
                    modelsContent = '<option value="' + value + '">' + value + '</option>';
                    $('select.models-select').append(modelsContent);
                });
            }
        });
    }

    $(document).delegate("select.brands-select", "change", function () {
        var vehicle_brand = $(this).val();
        getModeleInselectMarque(vehicle_brand);
    });
    $(document).delegate('#btn_filter_data_brand_model_by_image', 'click', function () {
        if ($('#btn_filter_data_brand_model_by_image').attr('data-can-send') === 'true') {
            $("#search-by-image-input").trigger('click');
        }
    });
    $('#btn_filter_data_brand_model_by_image').attr('data-can-send', 'true')
    $(document).delegate('#search-by-image-input', 'change', function () {
        var formData = new FormData();
        formData.append('file', $(this)[0].files[0]);
        var thisBtn = $(this);
        var x = $(this).next('label').clone();
        var attach = $('#search-by-image-input')[0].files[0].name;
        // $('label#ID1').text(attach);
        $("#nom-f").text(attach);
        var nom_bloc = document.getElementById("n-file");
        var add_bloc = document.getElementById("add-bloc");
        if (nom_bloc.style.display === "none") {
            nom_bloc.style.display = "block";
        }
        if (add_bloc.style.display === "block") {
            add_bloc.style.display = "none";
        }
        $(".alert-erreur").addClass("d-none");
        $(".spinner-image").removeClass("d-none");
        grecaptcha.ready(function () {
            grecaptcha.execute('6LcTqccZAAAAAOUjNHSsIAk75Ixymaghwepr6FbH', {action: 'submit'}).then(function (token) {
                $.ajax({
                    type: "POST",
                    url: "https://bridges.kifal.ma/car-recognizer",
                    data: formData,
                    processData: false,
                    contentType: false,
                    success: function (data) {
                        if (data?.success === true) {
                            // $(".alert-erreur").addClass("d-none");
                            pushDataLayer({
                                event: 'click_on_upload_image_recognition',
                                product_brand: data?.data?.brand,
                                product_model: data?.data?.model,
                                page: 'landing_page',
                                success: 'true'
                            })

                            $(".spinner-image").addClass("d-none");
                            $(".data-image").removeClass("d-none");
                            $(".title-image-brand-model").html(data?.data?.brand +" "+data?.data?.model);
                            $(".model-image").html(data?.data?.model);
                            $(".generation-image").html(data?.data?.generation);
                            $(".brand-image").html(data?.data?.brand);
                            $('.title-image-brand-model').attr("data-modele", data?.data?.model);
                            $('.title-image-brand-model').attr("data-brand", data?.data?.brand);

                        } else if (data?.success === false) {
                            $(".spinner-image").removeClass("d-none");
                            $(".alert-erreur").removeClass("d-none");

                            pushDataLayer({
                                event: 'click_on_upload_image_recognition',
                                product_brand: '',
                                product_model: '',
                                page: 'landing_page',
                                success: 'false'
                            })
                        }
                    },
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader('typeAuth', 'gr3');
                        xhr.setRequestHeader('token', token);
                        $('#btn_filter_data_brand_model_by_image').attr('data-can-send', 'false')
                    }, complete: function () {
                        $('#btn_filter_data_brand_model_by_image').attr('data-can-send', 'true');
                        $(".spinner-image").addClass("d-none");
                    },
                    error:function (xhr,ajaxOptions,thrownError){
                        $(".alert-erreur").removeClass("d-none");
                    }
                });
            });
        });
    });


    $(document).delegate('.search-occasion', 'click', function () {
        var brand = $('#filter_form_input_brand').val();
        var model = $('#list_models_filter').val();
        window.open("https://occasion.kifal.ma/annonces" + '?marque=' + brand + '&modele=' + model, '_blank')
    });
});

$(document).delegate('.search-neuf', 'click', function () {
    var brand = $('#filter_form_input_brand').val();
    var model = $('#list_models_filter').val();
    window.open("https://neuf.kifal.ma/search" + '?marque=' + brand + '&modele=' + model, '_blank')

});
$(document).delegate('.image-search-occasion', 'click', function () {
        var brand = $( ".title-image-brand-model" ).attr( "data-brand");
        var model = $( ".title-image-brand-model" ).attr( "data-modele");
        window.open("https://occasion.kifal.ma/annonces" + '?marque=' + brand + '&modele=' + model, '_blank')
    });
$(document).delegate('.image-search-neuf', 'click', function () {
    var brand = $( ".title-image-brand-model" ).attr( "data-brand");
    var model = $( ".title-image-brand-model" ).attr( "data-modele");
    window.open("https://neuf.kifal.ma/search" + '?marque=' + brand + '&modele=' + model, '_blank')

});

$(document).delegate('.image-search-credit', 'click', function () {
    var brand = $( ".title-image-brand-model" ).attr( "data-brand");
    var model = $( ".title-image-brand-model" ).attr( "data-modele");
    window.open("https://credit.kifal.ma/annonces" + '?marque=' + brand + '&modele=' + model, '_blank')

});

function pushDataLayerSearchByImage(eventBtn,source) {
    var brand = null;
    var model = null;
    // var source = null;
    brand = $( ".title-image-brand-model" ).attr( "data-brand");
    model =  $( ".title-image-brand-model" ).attr( "data-modele");
    pushDataLayer({
        event: eventBtn,
        product_brand: brand,
        product_model: model,
        source: source,
    })
}


